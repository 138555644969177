import React, { useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
// import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';
import '../../assets/components/artwork/artwork-slider.css';

// import Swiper core and required modules
import SwiperCore, { Pagination, EffectFade } from 'swiper/core';
import ImageFromField from './ImageFromField';
import ImageInView from '../fx/animate/ImageInView';
// replace from context
import { baseUrl, forceHTTPS, forceCMS } from '../../util/auth/drupal';

// install Swiper modules
SwiperCore.use([Pagination, EffectFade]);
const cms = '//cms.lochgallery.com';

const ArtworksSlider = ({ artworks, artworkMaxHeight, className }) => {
  // console.log(artworkMaxHeight);
  return (
    <>
      {artworks.length < 2 ? 
        /\.gif$/i.test(artworks[0]?.uri?.url) ? (
          <ImageInView src={`${cms}${artworks[0]?.uri?.url}`} render={true} style={{ maxHeight: artworkMaxHeight}} alt="" />
        ) : (
        <ImageFromField
          img={artworks[0]}
          style={{ maxHeight: artworkMaxHeight }}
          /* imageSize="gatsby_landing_page_banner" */
          imageSize="gatsby_artwork_image"
        />
        )
      : (
        <Swiper
          effect="fade"
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              let path = !artworks[index]?.uri.url.includes(cms)
                ? cms + artworks[index].uri.url
                : artworks[index].uri.url;

              return (
                '<span class="' +
                className +
                '" style="width: 2rem; height: 2rem;"><img src="' +
                path +
                '" alt="" style="width: 2rem; height: 2rem; object-fit: cover;" /></span>'
              );
            },
          }}
          slidesPerView={1}
          className={`artworks-slider ${className}`}
        >
          {artworks.map((artwork, i) => (
            <SwiperSlide key={i} height={artworkMaxHeight}>
              {/\.gif$/i.test(artwork?.uri?.url) ? (
                <ImageInView src={`${cms}${artwork?.uri?.url}`} render={true} style={{ maxHeight: artworkMaxHeight, margin: 'auto' }} alt="" />
              ) : (
                <ImageFromField
                  img={artwork}
                  style={{ maxHeight: artworkMaxHeight }}
                  render={true}
                  imageSize="gatsby_artwork_image"
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};
export default ArtworksSlider;
