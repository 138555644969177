/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import CompLink from '../components/global/Link';
import Layout from '../components/layout/Layout';
import useGetArtworks from '../util/hooks/useGetArtworks';
import { priceFixed, artworkDimensions } from '../components/helper/artwork';
import ActionButtons, { FollowBtn } from '../components/global/ActionButtons';
import ArtworkCard from '../components/global/ArtworkCard';
import useAuth from '../util/hooks/useAuth';
import SEO from '../components/SEO';
import ArtworksSlider from '../components/global/ArtworksSlider';
import { LeaseDisplay } from '../components/global/Lease';
import { baseUrl } from '../util/auth/drupal';
import IconEnlarge from '../components/icons/IconEnlarge';
import IconClose from '../components/icons/IconClose';
import { ReadMore } from '../components/global/ReadMore';
import { usePrintArtwork } from '../util/hooks/usePrintArtwork';

const ArtworkSinglePage = ({ data, pageContext, location }) => {
  useAuth();
  const [artworkMaxHeight, setArtworkMaxHeight] = useState('initial');
  const [device, setDevice] = useState('mobile');
  const [enlargedDisplay, setEnlargedDisplay] = useState(false);
  const [noAvailableLikeThis, setNoAvailableLikeThis] = useState(false);
  const [isPrinting, setIsPrinting] = usePrintArtwork();
  const {
    title,
    drupal_id,
    relationships,
    field_artwork_name,
    field_year_or_circa,
    field_art_price_original,
    field_price_avail_on_request,
    field_exceptional_artwork_sold,
    field_buy_now_button_embed,
    bodyContent,
    body,
  } = pageContext;

  const bodyProcessed = bodyContent || body?.processed || '';

  const { field_artwork_medium, field_location_ref, field_artwork_status } =
    relationships;

  const {
    artworks: artworksLikeThis,
    loadingArtworks,
    setArtworkIds,
  } = useGetArtworks();

  const { edges: availableArtwork = [] } = data.availableArtworks;

  const artworkDescription = relationships?.field_artist_ref?.body?.processed;

  const isBrowser = typeof window !== 'undefined';
  const artworkWrapper = useRef(null);

  useEffect(() => {
    if (isBrowser && artworkWrapper.current) {
      const bottomPadding = `1rem`;
      const offsetY = artworkWrapper.current.getBoundingClientRect().y;
      setArtworkMaxHeight(`calc(100vh - ${offsetY}px - ${bottomPadding})`);
    }
  }, []);

  useEffect(() => {
    const getArtworksLikeThis = async () => {
      try {
        const res = await fetch(
          `${baseUrl}/jsonapi/artworks_like_this/${drupal_id}`
        );

        if (res.ok) {
          const resData = await res.json();
          const { artworks } = resData;

          if (typeof artworks === 'object' && Object.keys(artworks).length) {
            const fieldPropSubItems = artworks;
            const the_ids = [];

            for (const property in fieldPropSubItems) {
              if (fieldPropSubItems[property].uuid) {
                the_ids.push(fieldPropSubItems[property].uuid);
              }
            }

            if (the_ids.length === 0) {
              setNoAvailableLikeThis(true);
            }

            setArtworkIds(the_ids);
          } else {
            setNoAvailableLikeThis(true);
          }
        }
      } catch (error) {
        console.log(error);
        setNoAvailableLikeThis(true);
      }
    };

    getArtworksLikeThis();
  }, [drupal_id, setArtworkIds]);

  // determine whether or not to display price
  const price_available_on_request_label = 'Price available on request';
  const artwork_price = priceFixed(field_art_price_original);
  const display_price = !Boolean(field_price_avail_on_request)
    ? artwork_price
    : field_artwork_status.name !== 'Sold'
    ? price_available_on_request_label
    : null;

  
  let imageThumb = null;
  
  try {
    imageThumb = relationships?.field_artwork_images[0].uri?.url;
  }catch(e) {
    // dont report.
  } 

  return (
    <Layout
      device={device}
      setDevice={setDevice}
      logo={data.logo}
      menu={data.allMenuMenuLink}
      locations={data.locations.edges}
      footerMenu={data.footer_menu}
      className={'artwork-page'}
    >
      <SEO title={field_artwork_name} image={imageThumb} />
      <section className="section t-left section-heading">
        <div className="t-heading-container container">
          <h2 className="t-uppercase artwork-title">{title}</h2>
        </div>
      </section>

      <section
        className={`section section-artwork ${
          isPrinting ? 'is-print-target' : ''
        }`}
      >
        <div className="container">
          <div className="columns artwork-data">
            <div className="column is-8">
              <div className="artwork-details-slider-wrapper" ref={artworkWrapper}>
                <ArtworksSlider
                  artworks={relationships.field_artwork_images}
                  /* artworkMaxHeight={artworkMaxHeight} */
                  artworkMaxHeight={`calc(100vh - 250px)`}
                />
              </div>
            </div>
            <div className="column is-4 artist-info">
              <div className="artist-mobile-l">
                <ul>
                  <li>
                    {relationships?.field_artist_ref?.path?.alias ? (
                      <h3>
                        <Link to={relationships.field_artist_ref?.path.alias}>
                          {relationships.field_artist_ref?.title}
                        </Link>
                      </h3>
                    ) : (
                      <h3>{relationships.field_artist_ref?.title}</h3>
                    )}
                  </li>
                  <li>
                    <FollowBtn
                      drupal_id={relationships.field_artist_ref?.drupal_id}
                    />
                  </li>
                </ul>
                <ul className="piece-details">
                  <li>{field_artwork_name}</li>
                  {field_artwork_medium && field_artwork_medium.length > 0 ? (
                    <li>
                      {field_artwork_medium
                        .map((medium) => {
                          return medium.name;
                        })
                        .join(', ')}
                    </li>
                  ) : null}
                  <li>{artworkDimensions(pageContext)}</li>
                  <li>{field_year_or_circa}</li>
                  {field_artwork_status.name === 'Sold' ? (
                    <li className="label-sold">Sold</li>
                  ) : (
                    <li>{display_price}</li>
                  )}
                  <li>{field_location_ref?.title}</li>
                </ul>
                </div>
                <div className="artist-mobile-r">
                <button
                  className="enlarge-img"
                  onClick={() => setEnlargedDisplay(true)}
                >
                  <IconEnlarge /> Enlarge Image
                </button>
                {!Boolean(field_price_avail_on_request) &&
                  artwork_price &&
                  field_art_price_original && 
                  field_artwork_status.name !== 'Sold' && (
                    <LeaseDisplay price={field_art_price_original} />
                  )}
                <ActionButtons
                  drupal_id={drupal_id}
                  url={`${location.href}`}
                  title={title}
                  location={field_location_ref?.title}
                  setIsPrinting={setIsPrinting}
                  showPrint
                />
                </div>
            </div>
          </div>
        </div>
          <div className="container artist-info">
            <br />
            <br />
            <div className={`artist-content`}>
            {/*field_buy_now_button_embed ? (
              <div className="buynow" dangerouslySetInnerHTML={{ __html: field_buy_now_button_embed }}></div>
            ) : null*/}
              <ReadMore>
                <div dangerouslySetInnerHTML={{ __html: bodyProcessed }}></div>
                <br />
                <div
                  dangerouslySetInnerHTML={{ __html: artworkDescription }}
                ></div>
              </ReadMore>
            </div>
          </div>
      </section>

      {availableArtwork && availableArtwork.length > 0 ? (
        <div>
          <div className="spacer"></div>
          <section className="section section-more-artwork-like-this">
            <div className="container">
              <h2 className="sub-section-title">
                More Artwork from this Artist
              </h2>
              <div className="spacer"></div>
              <div className="artworks grid-4 grid-2-md grid-1-sm ">
                {availableArtwork.map(({ node: artwork }, i) => (
                  <ArtworkCard
                    artwork={artwork}
                    device={device}
                    key={i}
                    artist_drupal_id={drupal_id}
                    className="grid-col"
                  />
                ))}
              </div>
              <div className="spacer"></div>
              {relationships.field_artist_ref?.path.alias && (
                <div className="t-right">
                  <CompLink
                    simplebutton
                    className={`btn-large btn-center btn-letterspacing`}
                    path={`${relationships.field_artist_ref?.path.alias}`}
                    linkkey={`exhibit_btn`}
                    device={device}
                  >
                    View all available artworks
                  </CompLink>
                </div>
              )}
            </div>
          </section>
        </div>
      ) : null}

      {!noAvailableLikeThis ? (
        <section className="section artwork-like-this t-left">
          <div className="t-heading-container container">
            <h2 className="sub-section-title">Artwork Like This</h2>
            <div className="spacer"></div>
            {loadingArtworks ? (
              <div className="artworks grid-4 grid-2-md grid-1-sm ">
                {new Array(8).fill(0).map((_, i) => (
                  <div key={i} className="artwork-skeleton grid-col"></div>
                ))}
              </div>
            ) : artworksLikeThis && artworksLikeThis.length > 0 ? (
              <div className="artworks grid-4 grid-2-md grid-1-sm ">
                {artworksLikeThis.map(({ data: artwork }) => (
                  <ArtworkCard
                    artwork={artwork}
                    device={device}
                    key={artwork.id}
                    className="grid-col"
                    artist_drupal_id={artwork.relationships.field_artist_ref.id}
                  />
                ))}
                <div className="spacer"></div>
              </div>
            ) : null}
            <div className="t-right">
              <CompLink
                simplebutton
                className={`btn-large btn-center btn-letterspacing`}
                path={`/artworks`}
                linkkey={`exhibit_btn`}
                device={device}
              >
                View all artworks
              </CompLink>
            </div>
          </div>
          <div className="spacer"></div>
        </section>
      ) : null}

      {enlargedDisplay ? (
        <div className="end-modal__wrap">
          <div className="end-modal__container">
            <div className="end-modal__image">
              <div className="columns">
                <div className="column is-12">
                  <ArtworksSlider
                    artworks={relationships.field_artwork_images}
                    artworkMaxHeight={`calc(100vh - 250px)`}
                    className="enlarged-slider"
                  />
                </div>
              </div>
            </div>
            <div className="end-modal__title">
              <h3>
                {field_artwork_name}{' '}
                {field_artwork_name && relationships.field_artist_ref?.title ? (
                  <span>|</span>
                ) : null}{' '}
                {relationships.field_artist_ref?.title}
              </h3>
            </div>
          </div>
          <button
            className="end-modal__close"
            onClick={() => setEnlargedDisplay(false)}
          >
            <IconClose />
          </button>
        </div>
      ) : null}
    </Layout>
  );
};

export default ArtworkSinglePage;

export const ArtworkSingleQuery = graphql`
  query ArtworkSingleQuery(
    $artist_field_firstname: String
    $artist_field_lastname: String
    $the__drupal_id: String
  ) {
    artworkDetails: allNodeArtwork(filter: {drupal_id: {eq: $the__drupal_id}}) {
      edges {
        node {
          field_buy_now_button_embed
        }
      }
    }
    availableArtworks: allNodeArtwork(
      filter: {
        status: { eq: true }
        relationships: {
          field_artist_ref: {
            field_firstname: { eq: $artist_field_firstname }
            field_lastname: { eq: $artist_field_lastname }
          }
          field_artwork_status: { name: { eq: "Available" } }
        }
        field_exceptional_artwork_sold: { ne: 1 }
      }
      sort: { fields: created, order: DESC }
      limit: 8
    ) {
      edges {
        node {
          drupal_id
          title
          created
          field_art_price_published
          field_art_year
          field_artist_payment
          field_artist_payment_amount
          field_artwork_name
          field_circa
          field_depth
          field_exceptional_artwork_sold
          field_height
          field_width
          field_dimensions_computed
          field_net_artwork_profit
          field_order_exceptional_sale
          field_buy_now_button_embed
          title
          field_searchable_price
          field_published_sold_artwork
          field_promoted_artwork
          field_price_avail_on_request
          field_year_or_circa
          field_art_price_original {
            currency_code
            number
          }
          relationships {
            field_location_ref {
              title
            }
            field_artwork_images {
              uri {
                url
              }
              image_style_uri {
                gatsby_artwork_image
                gatsby_artwork_thumbnail
                gatsby_landing_page_banner
              }
            }
            field_artist_ref {
              title
              path {
                alias
              }
              relationships {
                field_artist_type {
                  name
                }
              }
            }
            field_artwork_type {
              name
            }
            field_artwork_medium {
              name
            }
            field_artwork_status {
              name
            }
          }
          path {
            alias
          }
        }
      }
    }
    artworkCount: allNodeArtwork {
      totalCount
    }
    artistsMedium: allNodeArtist {
      edges {
        node {
          relationships {
            node__artwork {
              relationships {
                field_artwork_medium {
                  name
                  id
                }
              }
            }
          }
        }
      }
    }
    allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "gatsby-menu" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          drupal_parent_menu_item
          bundle
          drupal_id
          menu_name
        }
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
    locations: allNodeLocation {
      edges {
        node {
          field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
          }
          field_address_description
          field_address_short
          field_location_email
          field_location_fax_number
          field_location_phone_number
          title
          field_office_hours_description
        }
      }
    }
  }
`;
