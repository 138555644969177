import React, { useEffect, useState } from 'react';
import ChevronDown from '../../assets/media/chevron-down-solid.svg';
import {
  priceFixed,
  monthlyPriceFixed,
  buyoutPriceFixed,
  buyout5PriceFixed,
} from '../helper/artwork';
import Link from './Link';

export const LeaseCalculator = () => {
  const [price, setPrice] = useState({ number: 1000, currency_code: 'CAD' });
  const [monthlyPrice, setMonthlyPrice] = useState(monthlyPriceFixed(price));
  const [buyout_price, setBuyout_price] = useState(buyoutPriceFixed(price));
  const [buyout_5_price, setBuyout_5_price] = useState(
    buyout5PriceFixed(price)
  );

  useEffect(() => {
    setMonthlyPrice(monthlyPriceFixed(price));
    setBuyout_price(buyoutPriceFixed(price));
    setBuyout_5_price(buyout5PriceFixed(price));
  }, [price]);

  const handleChange = (e) => {
    setPrice({
      number: e.target.value,
      currency_code: 'CAD',
    });
  };

  return (
    <table className="lease-calculator">
      <tr>
        <td>
          <span>
            <p>Buy-out option rates are as follows:</p>
            <ul>
              <li>2 Years 60% of Original Value </li>
              <li>3 Years 40% of Original Value</li>
              <li>4 Years 20% of Original Value</li>
              <li>5 Years 10% of Original Value</li>
            </ul>
          </span>
        </td>
        <td>
          <div>
            <div>Original market value</div>
            <input type="number" value={price.number} onChange={handleChange} />
            <div>Monthly payment</div>
            <p>{monthlyPrice} + tax</p>
            <div>Buyout example (3 year)</div>
            <p>{buyout_price} + tax</p>
            <div>Buyout example (5 year)</div>
            <p>{buyout_5_price} + tax</p>
          </div>
        </td>
      </tr>
    </table>
  );
};

export const LeaseDisplay = ({ price }) => {
  const artwork_price = priceFixed(price);
  const monthly_price = monthlyPriceFixed(price);
  const buyout_price = buyoutPriceFixed(price);
  const buyout_5_price = buyout5PriceFixed(price);

  return (
    <div className="lease-calculator">
      <div className="dropdown is-hoverable">
        <div className="dropdown-trigger">
          <button
            className={`button drop`}
            aria-haspopup="true"
            aria-controls="artist_type"
          >
            <span>Lease Calculator</span>
            <span className="icon is-small">
              <img src={ChevronDown} />
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="artist_type" role="menu">
          <div className="dropdown-content">
            <div className="dropdown-item">
              <div className="content">
                <span>Original market value</span>
                <p>{artwork_price}</p>
                <span>Monthly payment</span>
                <p>{monthly_price} + tax</p>
                <span>Buyout example (3 year)</span>
                <p>{buyout_price} + tax</p>
                <span>Buyout example (5 year)</span>
                <p>{buyout_5_price} + tax</p>
                <Link path="/lease" simplebutton>
                  Learn more about leasing here
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
